export const DASHBOARD = '/dashboard'
export const SIGNUP = '/signup-login'
export const SEARCHPAGE = '/search'
export const VERIFICATIONPAGE = '/verify-email'
export const FORGOTPASSWORDPAGE = '/forgot-password'
export const PRIVACYPOLICY = '/privacy-policy'
export const TERMSOFUSE = '/terms-of-use'




export const API = {
    signup: 'api/users/signup',
    signup3rdparty: 'api/users/signup-with-3rd-party',
	login: 'api/users/login',
	createtoken: 'api/createtoken',
    getallemailaccountsforuser: 'api/getallemailaccountsforuser',
    getemailsforaccount: 'api/getemailsforaccount',
    getlinkpreview: 'api/getlinkpreview',
    getallsignupsforuser: 'api/getallsignupsforuser',
    getsignupsforuser: 'api/getsignupsforuser',
    getnumsignupsforuser: 'api/getnumsignupsforuser',
    setdescriptionforaccount: 'api/setdescriptionforaccount',
    addtagforaccount: 'api/addtagforaccount',
    removetagforaccount: 'api/removetagforaccount',
    deleteemailaccountandemails: 'api/deleteemailaccountandemails',
    getnumemailsforaccount: 'api/getnumemailsforaccount'
}