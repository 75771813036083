import axios from 'axios'
import * as ROUTES from 'routes.js'

export const API_ROOT = process.env.REACT_APP_SERVER_ADDRESS;

const requests = {
    delete: url =>
      axios
        .delete(`${API_ROOT}${url}`),

    get: url =>
      axios
        .get(`${API_ROOT}${url}`),

    put: (url, body) =>
      axios
        .put(`${API_ROOT}${url}`, body),

    post: (url, body) =>
      axios
        .post(`${API_ROOT}${url}`, body),

  };

export const Users = {

    signup: (email, password) => {        
        return requests.post(`${ROUTES.API.signup}`, {email: email, password: password,})
    },
    signupgooglecrumblybackend: (email, uid) => {        
        return requests.post(`${ROUTES.API.signup3rdparty}`, {email: email, uid: uid  })
    },
    login: (email, password) => {        
        return requests.post(`${ROUTES.API.login}`, {email: email, password: password})
    },
}

export const FirebaseBackendFunctions = {
    createtoken: (uid) => {        
        return requests.post(`${ROUTES.API.createtoken}`, {uid: uid})
    },
}

export const Email = {
  
  getAllEmailsForEmailAccount: (email) => {        
    return requests.post(`${ROUTES.API.getallemailsforemailaccount}`, {email: email,})
  },

  getAllEmailAccountsForUser: async (email) => {        
    const data = await requests.post(`${ROUTES.API.getallemailaccountsforuser}`, {email: email,})
    return data.data
  },

  getEmailsForAccount: async (email, page, numEmailsPerPage) => {        
    const data = await requests.post(`${ROUTES.API.getemailsforaccount}`, {crumblyEmail: email, page: page, numEmailsPerPage: numEmailsPerPage})
    return data.data
  },

  setDescriptionForAccount: (email, description) => {        
    return requests.post(`${ROUTES.API.setdescriptionforaccount}`, {email: email, description: description})
  },
  addTagForAccount: (email, tag) => {        
    return requests.post(`${ROUTES.API.addtagforaccount}`, {email: email, tag: tag})
  },
  removeTagForAccount: (email, tag) => {        
    return requests.post(`${ROUTES.API.removetagforaccount}`, {email: email, tag: tag})
  },

  deleteEmailAccountAndEmails: (email) => {        
    return requests.post(`${ROUTES.API.deleteemailaccountandemails}`, {email: email})
  },
  getNumEmailsForAccount: (crumblyEmail) => {        
    return requests.post(`${ROUTES.API.getnumemailsforaccount}`, {crumblyEmail: crumblyEmail})
  },
}

export const Links = {
  getLinkPreview: async (link) => {        
    const data = await requests.post(`${ROUTES.API.getlinkpreview}`, {link: link})
    return data.data
  },
  getAllSignupsForUser: async (email) => {        
    const data = await requests.post(`${ROUTES.API.getallsignupsforuser}`, {email: email})
    return data.data
  },
  getSignupsForUser: async (id_last, email, limit, less_or_more) => {        
    const data = await requests.post(`${ROUTES.API.getsignupsforuser}`, {id_last: id_last, email: email, limit: limit, less_or_more: less_or_more})
    return data.data
  },
  getNumSignupsForUser: async (email) => {        
    const data = await requests.post(`${ROUTES.API.getnumsignupsforuser}`, {email: email})
    return data.data
  },
}

export default requests